.search-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.filter-container {
    display: flex;  
    justify-content: space-between;
}

@media screen and (max-width: 875px) {
    .search-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .search-input {
        margin-bottom: 1rem;
    }
    .search-container :nth-child(1) {
        order: 2;
    }

    .search-container :nth-child(2) {
        order: 1;
    }

    .filter-container :nth-child(1) {
        order: 1;
    }

    .filter-container :nth-child(2) {
        order: 2;
    }
}