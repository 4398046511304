.book-detail {
    display: flex;
    padding: 1.2rem;
}

.book-content {
    padding-left: 1.2rem; 
    padding-right: 1.2rem; 
    width: 80%;
}

.book-image-container {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.book-image-container > button {
    background-color: black !important;
    color: white !important;
}

.book-image-container > button:hover {
    background-color: rgb(32, 32, 32) !important;
    color: white !important;
}

.book-image__button {
    background-color: black;
    color: white;
}

.book-image__button:hover {
    background-color: rgb(32, 32, 32) !important;
    color: white !important;
    border-color: white !important;
}

.book-image {
    border-radius: 1.2rem; 
    padding-left: 0;
}

@media screen and (max-width: 1280px) {
    .book-detail {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .book-content {
        padding-left: 1.2rem; 
        padding-right: 1.2rem; 
        width: 100%;
    }
}