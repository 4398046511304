.avatar-container {
    padding-right: 1.2rem;
    padding-left: 1.2rem;
    cursor: "pointer";
}

@media screen and (max-width: 990px) {
    .avatar-container {
        display: none;
    }
}